import { observable, action, computed, toJS, runInAction } from 'mobx';
import { persist, create } from 'mobx-persist';
import agent from 'utils/agent';
import { cartStore } from './cart.store';
import { toast, ToastContainer } from 'react-nextjs-toast';
// import moment from 'moment';
// import navigationStore from './navigationStore';
// import agent from '../services/agent';
// import configStore from './configStore';

class AuthStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable lang = 'en';

  @persist @observable vegOnly = false;

  @observable inProgress = false;

  @observable errors = undefined;

  @observable message = undefined;

  @observable isAddressOpen = false;

  @observable isAddressFormOpen = false;

  @observable errors = [];

  @persist('object') @observable user = {};

  @persist @observable token = '';

  @persist('object') @observable currentAddress = {};

  @persist @observable mobile = '';

  @persist('object') @observable lastOrder = {};

  @action setError(id) {
    this.errors.push(id);
    // this.okToAddCart = 0;
  }

  @action setUser(user) {
    this.user = user;
  }

  @action setLang(lang) {
    this.lang = lang;
  }

  @action setVegOnly(value) {
    // console.log('SETTING VEG:', value);
    this.vegOnly = value;
  }

  @action setLastOrder(order) {
    this.lastOrder = order;
  }

  @action setMobile(mobile) {
    this.mobile = mobile;
  }

  @action setToken(token) {
    this.token = token;
  }

  @action resetUser() {
    this.token = '';
    this.user = {};
    this.mobile = '';
    this.currentAddress = {};
    this.lastOrder = {};
  }

  @action setAddress(address) {
    // console.log('Address:', toJS(address), toJS(this.currentAddress));

    this.currentAddress = address;
    // cartStore.loadOutlet(address.area_id);
    this.closeAddress();
  }

  @action deleteAddress(id) {
    agent.Data.single(`/user/address/delete/${id}`).then((res) => {
      // console.log('ADDRESSES:', res);
      runInAction(() => {
        this.loadAddresses();
      });
    });
  }

  @action loadAddresses() {
    agent.Data.all('/user/address/list')
      .then((res) => {
        // console.log('ADDRESSES:', res);
        runInAction(() => {
          this.user.addresses = res.addresses;
        });
      })
      .catch((err) => {
        if (err.response && err.response.status) {
          if (err.response.status == 401) {
            toast.notify(`Please login or register to continue.`, {
              title: 'Unauthenticated.',
              type: 'error'
            });
          } else {
            // console.log('Load address error, status not 401')
            toast.notify(`Something went wrong!!!`, {
              title: 'Error.',
              type: 'error'
            });
          }
        } else {
          // console.log('Load address error, no response')
          toast.notify(`Something went wrong!!!`, {
            title: 'Error.',
            type: 'error'
          });
        }
      });
  }

  @action getUser() {
    agent.Data.all('/user').then((res) => {
      // console.log('USER:', res);
      runInAction(() => {
        this.user = res.user;
      });
    }).catch(err => {

    });
  }

  @action setAddresses(addresses) {
    this.user.addresses = addresses;
  }

  @action openAddress() {
    // console.log('OPEN');
    this.isAddressOpen = true;
  }

  @action closeAddress() {
    // console.log('CLOSE');
    this.isAddressOpen = false;
  }

  @action openAddressForm() {
    // console.log('OPEN');
    this.isAddressFormOpen = true;
  }

  @action closeAddressForm() {
    // console.log('CLOSE');
    this.isAddressFormOpen = false;
  }

  @action clearError(id) {
    const errors = toJS(this.errors).filter((er) => er != id);
    this.errors = errors;
    // this.okToAddCart = 1;
  }
}

const hydrate = create({
  // storage: sessionStorage,   // or AsyncStorage in react-native.
  //                         // default: localStorage
  // jsonify: false  // if you use AsyncStorage, here shoud be true
  // default: true
});

// create the state
export const authStore = new AuthStore();
if (process.browser) {
  hydrate('cart-auth', authStore).then(() =>
    console.log('')
  );
}

export default AuthStore;
