import { createContext, useReducer } from 'react';

export const RestaurantContext = createContext({});

const initialState = {
  restaurant: {},
  area: {},
  outlet: {}
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_RESTAURANT':
      return {
        ...state,
        restaurant: action.payload
      };
    case 'SET_AREA':
      return {
        ...state,
        area: action.payload
      };
    case 'SET_OUTLET':
      return {
        ...state,
        outlet: action.payload
      };
    default:
      return state;
  }
}

export const RestaurantProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <RestaurantContext.Provider value={{ state, dispatch }}>
      {children}
    </RestaurantContext.Provider>
  );
};
