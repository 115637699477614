import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
// import env from '../environment';
// import { helpers } from '../helpers/helpers';

// import RootStore from '../store/rootStore';
// import rootStore from '../store/rootStore';
import { authStore } from '../stores/auth.store';
import { cartStore } from '../stores/cart.store';
import Router from 'next/router';

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = env.apiUrl;
const API_ROOT = `${process.env.NEXT_PUBLIC_API_URL}/api`;

const handleErrors = async (err) => {
  // console.log('ERRROOOORRRR');
  if (err && err.response && err.response.status === 401) {
    const req = err.response.req;
    // console.log('logging out......', req);
    if (req.url.includes('loyalty')) {
      // console.log('IS LOYALTY......');
    } else {
      if (process.browser) {
        if (cartStore.currentRestaurant.domain) Router.push(`/auth`);
        else
          Router.push(
            `/[restaurant]/auth`,
            `/${cartStore.currentRestaurant.url}/auth`
          );
      }
    }
    // userStore.logout();
  } else if (err && err.response && err.response.status === 403) {
    // console.log('EROR::', err.status);
    if (process.browser) {
      if (cartStore.currentRestaurant.domain) Router.push(`/`);
      else Router.push(`/[restaurant]/`, `/${cartStore.currentRestaurant.url}`);
    }
    // return err
    // authStore.logout();
    // alert('Unable to connect to the server. Please check your internet connection.')
    // a = alert(err);
    // helpers.showMessage([err].join());
  }
  return err;
};

const responseBody = (res) => res.body;

const tokenPlugin = (req) => {
  req.set('Content-Type', 'application/json');
  req.set('Accept', 'application/json');
  req.set('Access-Control-Allow-Origin', '*');
  if (authStore.token) {
    req.set('Authorization', `${authStore.token}`);
  }
  if (authStore.lang) {
    req.set('lang', `${authStore.lang}`);
  }
  if (cartStore.currentRestaurant && cartStore.currentRestaurant.id) {
    // console.log('RESTAURANT:', cartStore.currentRestaurant.id);
    req.set('restaurant-id', `${cartStore.currentRestaurant.id}`);
  }
  if (cartStore.currentOutlet && cartStore.currentOutlet.id) {
    req.set('outlet-id', `${cartStore.currentOutlet.id}`);
  }
};

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) => {
    // console.log('POST:', body);
    return superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody);
  },
  upload: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody)
};

const Auth = {
  // current: () => requests.get('/auth/user'),
  login: (form) => requests.post('/user/otp', form),
  loginVerify: (form) => requests.post('/user/otp-verify', form),
  register: (user) => requests.put('/user/register', { user })
};

const Data = {
  all: (url) => requests.get(`${url}`),
  single: (url) => requests.get(`${url}`),
  post: (url, data) => requests.post(`${url}`, { ...data })
};

export default {
  Auth,
  Data
};
