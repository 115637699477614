import { useStaticRendering } from 'mobx-react';

import AuthStore from './auth.store';
import CartStore from './cart.store';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);

let store = null;

export default function initializeStore(
  initialData = { authStore: {}, cartStore: {} }
) {
  if (isServer) {
    return {
      authStore: new AuthStore(),
      cartStore: new CartStore()
    };
  }
  if (store === null) {
    store = {
      authStore: new AuthStore(),
      cartStore: new CartStore()
    };
  }

  return store;
}
