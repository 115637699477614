import React, { useContext } from 'react';
import App from 'next/app';
import { appWithTranslation } from '../i18n';
import { I18nContext } from 'next-i18next';
import { Provider } from 'mobx-react';
import { DefaultSeo } from 'next-seo';
import { PageTransition } from 'next-page-transitions'

import 'react-phone-number-input/style.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'assets/tailwind.css';
// import "react-multi-carousel/lib/styles.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ThemeProvider } from 'styled-components';
import { GlobalStyle, RtlStyle } from 'styled/global.style';
import { theme } from 'theme';
import toast, { ToastContainer } from 'react-nextjs-toast';
import { RestaurantProvider } from 'contexts/restaurant.context';
import AppLayout from 'containers/AppLayout/AppLayout';
import 'mobx-react/batchingForReactDom';
import { library } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons'

import initializeStore from '../stores/store';

import {
  faTrashAlt,
  faClock,
  faShoppingBag,
  faTimes,
  faPlus,
  faMinus,
  faMapMarkerAlt,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faShoppingBasket,
  faCheckCircle,
  faCheck,
  faCircle,
  faDotCircle,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faExclamationCircle,
  faTag,
  faTags,
  faPhone,
  faBars,
  faTrash,
  faTicketAlt,
  faSignOutAlt,
  faGift,
  faPencilAlt,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { faGem } from '@fortawesome/free-regular-svg-icons';

library.add(
  faTrashAlt,
  faClock,
  faShoppingBag,
  faTimes,
  faPlus,
  faMinus,
  faMapMarkerAlt,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faShoppingBasket,
  faCheckCircle,
  faCheck,
  faCircle,
  faDotCircle,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faExclamationCircle,
  faTag,
  faTags,
  faPhone,
  faBars,
  faTrash,
  faTicketAlt,
  faSignOutAlt,
  faGem,
  faGift,
  faPencilAlt,
  faSave,
);
import * as Sentry from '@sentry/node';
import { GTMPageView } from 'utils/_gtm';
import Router from 'next/router';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  });
}

function ExtendedApp({
  Component,
  pageProps,
  userAgent,
  locale,
  query,
  i18n,
  initialMobxState
}) {
  // api.defaults.baseURL = process.env.API_URL;
  // const deviceType = useDeviceType(userAgent);
  const {
    i18n: { language }
  } = useContext(I18nContext);

  const isServer = typeof window === 'undefined';
  const mobxStore = initialMobxState;

  // Initiate GTM
  React.useEffect(() => {
    const handleRouteChange = (url) => GTMPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);
    
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };


  }, []);

  // // console.log('LOCALE:', locale, i18n);
  return (
    <ThemeProvider theme={theme}>
      <PageTransition timeout={300} classNames="page-transition">
      <>
        <ToastContainer />
        {/* <AppLayout> */}
          <Component
            {...pageProps}
            // deviceType={deviceType}
          />
        {/* </AppLayout> */}
        <GlobalStyle />
        {language === 'ar' && <RtlStyle />}
      </>
        </PageTransition>
    </ThemeProvider>
  );
}

ExtendedApp.getInitialProps = async (appContext) => {
  const mobxStore = initializeStore();
  // appContext.ctx.mobxStore = mobxStore;
  const appProps = await App.getInitialProps(appContext);
  const { req, query } = appContext.ctx;
  const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;
  // const { locale } = parseCookies(req);
  return {
    ...appProps,
    userAgent,
    query,
    namespacesRequired: ['common'],
    initialMobxState: mobxStore
  };
};

scrollPositionRestorer();

function scrollPositionRestorer() {
  const scrollMemories = {};
  let isPop = false;

  if (process.browser) {
    window.history.scrollRestoration = 'manual';
    window.onpopstate = () => {
      // console.log('ONPOP');
      isPop = true;
    };
  }

  Router.events.on('routeChangeStart', () => {
    saveScroll();
  });

  Router.events.on('routeChangeComplete', () => {
    // if (isPop) {
    restoreScroll();
    //   isPop = false;
    // } else {
    //   scrollToTop();
    // }
  });

  function saveScroll() {
    scrollMemories[Router.asPath] = window.scrollY;
  }

  function restoreScroll() {
    const prevScrollY = scrollMemories[Router.asPath];
    if (prevScrollY !== undefined) {
      window.requestAnimationFrame(() => {
        // console.log('TTT');
        setTimeout(() => {
          window.scrollTo(0, prevScrollY);
        }, 300);
      });
      // window.requestAnimationFrame(() => window.scrollTo(0, prevScrollY));
    }
  }

  function scrollToTop() {
    window.requestAnimationFrame(() => window.scrollTo(0, 0));
  }
}

export default appWithTranslation(ExtendedApp);
