import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
// import env from '../environment';
// import { helpers } from '../helpers/helpers';

// import RootStore from '../store/rootStore';
// import rootStore from '../store/rootStore';
import { authStore } from '../stores/auth.store';
import { cartStore } from '../stores/cart.store';
import Router from 'next/router';

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = env.apiUrl;
const API_ROOT = `${process.env.NEXT_PUBLIC_API_URL_CATALOGUE}`;

const handleErrors = async (err) => {
  // console.log('ERRROOOORRRR');
  if (err && err.response && err.response.status === 401) {
    const req =  err.response.req;
    // console.log('logging out......', req);
    if (req.url.includes('loyalty')) {
      // console.log('IS LOYALTY......');
    }
    else {
      if (process.browser){
        Router.push(
          `/[restaurant]/auth`,
          `/${cartStore.currentRestaurant.url}/auth`,
          {
            // shallow: true,
          }
        );
        }
    }
    // userStore.logout();
  } else if (err && err.response && err.response.status === 403) {
    // console.log('EROR::', err.status);
    if (process.browser){
      Router.push(`/[restaurant]/`, `/${cartStore.currentRestaurant.url}`, {
        // shallow: true,
      });
    }
    // return err
    // authStore.logout();
    // alert('Unable to connect to the server. Please check your internet connection.')
    // a = alert(err);
    // helpers.showMessage([err].join());
  }
  return err;
};

const responseBody = (res) => res.body;

const tokenPlugin = (req) => {
  req.set('Content-Type', 'application/json');
  req.set('Accept', 'application/json');
  req.set('Access-Control-Allow-Origin', '*');
  if (authStore.token) {
    req.set('Authorization', `${authStore.token}`);
  }
  if (authStore.lang) {
    req.set('lang', `${authStore.lang}`);
  }
  // if (cartStore.currentRestaurant && cartStore.currentRestaurant.id) {
  //   // console.log('RESTAURANT:', cartStore.currentRestaurant.id);
  //   req.set('business-id', `${cartStore.currentRestaurant.id}`);
  // }
  // if (cartStore.currentOutlet && cartStore.currentOutlet.id) {
  //   req.set('outlet-id', `${cartStore.currentOutlet.id}`);
  // }
};

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody),
  post: (url, body) => {
    // console.log('POST:', body);
    return superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody);
  },
  upload: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors)
      .then(responseBody)
};

export const Catalogue = {
  all: (url) => requests.get(`${url}`),
  single: (url) => requests.get(`${url}`),
  post: (url, data) => requests.post(`${url}`, { ...data })
};

export default {
  Business: Catalogue
};
