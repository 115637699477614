import { observable, action, computed, toJS, runInAction } from 'mobx';
import { persist, create } from 'mobx-persist';
import localForage from 'localforage';
import { v4 as uuidv4 } from 'uuid';
import numbro from 'numbro';
import moment from 'moment';
import _ from 'lodash';

// import navigationStore from './navigationStore';
// import agent from '../services/agent';
// import configStore from './configStore';
import { toast, ToastContainer } from 'react-nextjs-toast';
import Router from 'next/router';
import agent from 'utils/agent';
import { authStore } from './auth.store';
import { showErrorMessage } from 'utils/_helper';
import { Business } from 'utils/_agent-business';
import { Catalogue } from 'utils/_agent-catalogue';
import { addMinutes, isBefore } from 'date-fns';
class CartStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable myName = 'Thaha';

  @observable inProgress = false;

  @observable addingToCart = false;

  @observable errors = undefined;

  @observable message = undefined;

  @persist @observable orderType = null;
  @persist @observable channel = 'web';

  @persist('list') @observable cart = [];

  @observable errors = [];

  @observable okToAddCart = 1;

  @observable currentItem = {};

  @persist('object') @observable paymentMethod = {};

  @observable deliverySchedule = null;

  @observable quantity = 1;

  @observable note = '';

  // @observable searchTerm = 'beef';

  @observable currentAttributes = [];

  @observable isAddressOpen = false;

  @observable isAddressFormOpen = false;
  @observable isOutletChanged = false;

  @persist('object')
  @observable
  currentRestaurant = {};

  @persist('object') @observable currentArea = {};
  @persist('object') @observable currentGeoLocation = {};

  @persist('object') @observable deliveryDetails = {};
  @persist('object') @observable deliveryInfo = {}; //delivery charge, delivery time, ets based on zone or outlet

  @persist('object') @observable currentOutlet = {};

  // @persist('list')
  @observable currentCategories = null;
  @observable usingPreloadMenu = false;
  
  @observable currentMenu = {};
  @observable menuOpened = false;
  
  @persist('object') @observable user = {};
  @observable userForm = {};

  @persist('object') @observable currentAddress = {};
  @persist @observable tempAddress = null;

  @persist @observable mobile = '';
  @persist @observable listType = 'grid';

  @observable changingOrderType = false;
  @observable changingArea = false;
  @observable isSubmitting = false;

  @observable discounts = [];
  @persist('object') @observable discount = {};
  @observable discountTemp = {};
  @observable loyaltyValue = 0;

  @observable stripe_payment_ref = null;

  @action setError(id) {
    this.errors.push(id);
    // this.okToAddCart = 0;
  }

  @action setOrderType(type = 'delivery') {
    // console.log('TYPE', type);
    this.changingOrderType = true;
    this.orderType = type;
  }

  @action setListType(type = 'list') {
    // console.log(type);
    this.listType = type;
  }

  @action setUserForm(field, value) {
    // console.log(type);
    this.userForm = {
      ...this.userForm,
      [field]: value
    };
  }

  @action setStripePaymentMethodId(value) {
    // console.log('CARD::', value);
    this.stripe_payment_ref = value;
  }

  @action setLoyaltyValue(value) {
    this.loyaltyValue = value;
  }

  @action setPreloadMenuMode(value) {
    this.usingPreloadMenu = value;
  }

  @action setOrderTypeFinished() {
    this.changingOrderType = false;
  }

  @action areaChangeFinished() {
    this.changingArea = false;
  }

  @action testAction() {
    return new Promise(async (resolve, reject) => {
      return resolve('Thaha');
    });
  }

  @action setRestaurant(restaurant) {
    // console.log('SETTING  REST::', restaurant)
    this.currentRestaurant = restaurant;
  }

  @action setPaymentMethod(method) {
    this.paymentMethod = method;
    // console.log('PM:', method);
  }

  @action setChannel(channel) {
    this.channel = channel;
  }

  @action setCurrentMenu(menu) {
    this.currentMenu = menu;
    this.menuOpened = true;
  }

  @action closeMenu() {
    this.menuOpened = false;
  }

  @action setDeliverySchedule(schedule) {
    this.deliverySchedule = schedule;
    // console.log('DS:', schedule);
  }

  @action setDiscounts(discounts) {
    this.discounts = discounts;
  }

  @action clearDiscount() {
    this.discount = null;
  }

  @action selectDiscount(discount) {
    this.discount = null;
    this.discountTemp = discount;
    this.applyDiscount(discount.code);
  }

  @action async applyDiscount(code) {
    // console.log('APLYING DISC:');
    // this.discountTemp = discount;

    const data = {
      ordering_service: this.orderType,
      discount_code: code
    };

    await agent.Data.post(`/discounts/apply`, data)
      .then((res) => {
        if (res.discount && res.discount.id) {
          runInAction(() => {
            this.discount = res.discount;
            this.discountTemp = null;

            toast.notify('The selected coupon code is applied.', {
              title: 'Coupon applied!',
              type: 'success'
            });

            if (this.loyaltyValue) {
              toast.notify('Your loyalty savings has been removed.', {
                title: 'Loyalty cleared!',
                type: 'success'
              });

              this.setLoyaltyValue(0);
            }
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status) {
          if (err.response.status == 422) {
            // console.log('Restaurant not found', err.response);
            // toast.notify(`Invalid coupon code.`, {
            //   title: 'Error.',
            //   type: 'error'
            // });
            showErrorMessage(err, 'Coupon Error');
          } else if (err.response.status == 401) {
            // console.log('Restaurant not found', err.response);
            toast.notify(`Please login to apply coupon.`, {
              title: 'Coupon not applied.',
              type: 'error'
            });
          }
        } else {
          showErrorMessage(err, 'Coupon Error');
          // toast.notify(`Something went wrong!!!`, {
          //   title: 'Error.',
          //   type: 'error'
          // });
        }
      });
  }

  @action goToPage() {
    Router.push('/');
  }

  @action setArea(area) {
    this.changingArea = true;
    // console.log('AREA:', area, toJS(this.currentOutlet));
    // if (this.cart.length) {
    //   const f = this.currentOutlet.areas.filter((el) => el.id === area.id);
    //   if (toJS(f).length > 0) {
    //     console.log("AREA MATCHEDD", area, toJS(f));
    //   } else {
    //     toast.notify(
    //       `You are changing your location. Your cart will be cleared.`,
    //       { title: "Area changed!" }
    //     );

    //     this.resetCart();
    //     this.currentOutlet = {};
    //     console.log("AREA DOESNT MATCH", area, toJS(f));
    //   }
    // }
    // console.log('AREA::', area);
    this.currentArea = area;
  }

  @action setGeoLocation(location) {
    this.currentGeoLocation = location;
  }

  @action async loadOutlet(id, source = 'area', redirect = true) {
    // console.log('LOADING OUTLET:', this.currentRestaurant.url, id, source);
    this.isOutletChanged = false;
    let outlet = null;
    let url = '';
    switch (source) {
      case 'area':
        // url = `/outlets?area=${id}`;
        url = `/business/${this.currentRestaurant.url}/outlets/area/${id}`;
        break;

      case 'map':
        url = `/business/${this.currentRestaurant.url}/outlets/?lat=${id.lat}&lng=${id.lng}`;
        break;

      default:
        url = `/business/${this.currentRestaurant.url}/outlets/${id}`;
        break;
    }
    // const url =
    //   source === 'area' ? `/outlets?area=${id}` : `/outlets?url=${id}`;
    // return await agent.Data.all(url)
    await Business.all(url)
      .then((res) => {
        // console.log('OUTLET:', res);
        runInAction(() => {
          // if (res.data && res.data[0] && res.data[0].id) {
          if (res && res.id && res.url) {
            if ((source == 'area' || source == 'map') && !res.has_delivery) {
              toast.notify(`Sorry, we dont deliver in this area...`, {
                title: 'Outside delivery area',
                type: 'error'
              });
              Router.push(`/[restaurant]`, `/${this.currentRestaurant.url}`, {
                shallow: true
              });
            } else if (source == 'outlet' && !res.has_pickup) {
              toast.notify(`Outlet does not accept pickup orders now.`, {
                title: 'Error.',
                type: 'warn'
              });

              Router.push(`/[restaurant]`, `/${this.currentRestaurant.url}`, {
                shallow: true
              });

              return;
            } else {
              // console.log('OU LOADED');

              if (
                this.cart.length &&
                this.currentOutlet &&
                this.currentOutlet.id &&
                this.currentOutlet.id !== res.id
              ) {
                toast.notify(
                  `You are changing your location. Your cart will be cleared.`,
                  {
                    title: 'Area changed!',
                    type: 'warn'
                  }
                );

                // onAreaSelect(area);

                // cartStore.resetOutlet();

                // cartStore.setArea(area);
                // cartStore.loadOutlet(area.id);
                cartStore.resetCart();
                // console.log('AREA DOESNT MATCH', area, toJS(f));
              }
              // onAreaSelect(area, 'reset');

              this.currentOutlet = res;
              outlet = res;
              if (res.selected_area) {
                this.currentArea = res.selected_area;
              }

              this.isOutletChanged = true;
              this.setPaymentMethod({});

              // console.log('LOAD MENU BEFORE PASSING OUTLET BACK:');
              this.loadMenu();

              // console.log('PASSING OUTLET BACK:', res);
              return res;
            }
          } else {
            if (source === 'map') {
              toast.notify(`Sorry, we dont deliver in this area.`, {
                title: 'Outside delivery area',
                type: 'error'
              });
            }
          }

          // Router.push(
          //   `/[restaurant]/outlets/[store]`,
          //   `/${this.currentRestaurant.url}/outlets/${this.currentOutlet.url}`,
          //   { shallow: true }
          // );
        });
        // console.log('AREA DATA: ', res);
      })
      .then(() => {
        // this.loadMenu();
      })
      .catch((err) => {
        // console.log('ERR::', err);
        if (err.response && err.response.status && err.response.status == 404) {
          // console.log('Restaurant not found', err.response);
          if (source === 'map') {
            toast.notify(`Sorry, we dont deliver in this area.`, {
              title: 'Outside delivery area',
              type: 'error'
            });
          } else {
            toast.notify(`Outlet not found.`, {
              title: 'Error.',
              type: 'error'
            });
          }
        } else {
          // console.log('Load outlet error');
          toast.notify(`Something went wrong!!!`, {
            title: 'Error.',
            type: 'error'
          });
        }
      });

    return outlet;
  }

  @action async loadDefaultMenu() {
    const url = `/business/${this.currentRestaurant.url}`;
    await Catalogue.all(url)
      .then((res) => {
        // console.log('OUTLETS:', res);
        runInAction(() => {
          // console.log('OU LOADED');
          this.currentCategories = res;
        });
        // console.log('AREA DATA: ', res);
      })
      .catch((err) => {
        // if (err.response && err.response.status && err.response.status == 404) {
        // console.log('menu load error');
        toast.notify(`Unable to load menu`, {
          title: 'Error.',
          type: 'error'
        });
        // }
      });
  }

  @action async loadMenu() {
    // console.log('MMMMEEEENNNNUUUU');
    const url = `/business/${this.currentRestaurant.url}/${this.currentOutlet.url}`;
    await Catalogue.all(url)
      .then((res) => {
        // console.log('OUTLETS:', res);
        runInAction(() => {
          // console.log('OU LOADED');
          this.currentCategories = res;
        });
        // console.log('AREA DATA: ', res);
      })
      .catch((err) => {
        // if (err.response && err.response.status && err.response.status == 404) {
        // console.log('menu load error');
        toast.notify(`Unable to load menu`, {
          title: 'Error.',
          type: 'error'
        });
        // }
      });
  }

  @action async loadAreaDetails() {
    // console.log('LOADING AREA DETAILS ');
    await agent.Data.all(`/area-details?area=${this.currentArea.id}`)
      .then((res) => {
        // console.log('AREA DETAILS:', res);
        runInAction(() => {
          // console.log('OU LOADED');
          this.deliveryDetails = res.data;
        });
        // console.log('AREA DATA: ', res);
      })
      .catch((err) => {
        // if (err.response && err.response.status && err.response.status == 404) {
        // console.log('area load error');
        toast.notify(`Unable to load delivery details`, {
          title: 'Error.',
          type: 'error'
        });
        // }
      });
  }

  @action async loadDeliveryInfo(id, type = 'map') {
    // console.log('LOADING AREA DETAILS ');
    let url = '';
    switch (type) {
      case 'area':
        // url = `/outlets?area=${id}`;
        url = `/business/${this.currentRestaurant.url}/delivery-info?area_id=${id}`;
        break;

      case 'map':
        url = `/business/${this.currentRestaurant.url}/delivery-info?lat=${id.lat}&lng=${id.lng}`;
        break;
    }
    await Business.all(url)
      .then((res) => {
        // console.log('AREA DETAILS:', res);
        runInAction(() => {
          // console.log('OU LOADED');
          this.deliveryInfo = res;
        });
        // console.log('AREA DATA: ', res);
      })
      .catch((err) => {
        if (err.response && err.response.status && err.response.status == 404) {
          // console.log('area load error');
          toast.notify(`We dont deliver in this area.`, {
            title: 'Error.',
            type: 'error'
          });
        } else {
          // console.log('area load error');
          toast.notify(`Unable to load delivery details`, {
            title: 'Error.',
            type: 'error'
          });
        }
      });
  }

  // @action searchMenu(text = '') {
  //   this.searchTerm = text;
  // }

  // @computed get filteredMenus() {
  //   return this.searchTerm;
  // }
  @computed get categories() {
    // return this.currentOutlet.categories.filter((category) =>
    //   category.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    // );

    if (authStore.vegOnly) {
      return this.currentOutlet.categories.map((category) => {
        return category.items.filter((item) => item.is_veg == true);
      });
    }
    return this.currentOutlet.categories;
  }

  // @action async loadOutlets() {
  //   console.log('Area Selected');
  //   this.isOutletChanged = false;
  //   const url =
  //     source === 'area' ? `/outlets?area=${id}` : `/outlets?url=${id}`;
  //   await agent.Data.all(url).then((res) => {
  //     console.log('OUTLETS:', res);
  //     runInAction(() => {
  //       this.currentOutlet = res.data[0];
  //       this.isOutletChanged = true;

  //       // Router.push(
  //       //   `/[restaurant]/outlets/[store]`,
  //       //   `/${this.currentRestaurant.url}/outlets/${this.currentOutlet.url}`,
  //       //   { shallow: true }
  //       // );
  //     });
  //     console.log('AREA DATA: ', res);
  //   });
  // }

  @action setOutlet(outlet) {
    this.currentOutlet = outlet;
  }

  @action setMobile(mobile) {
    this.mobile = mobile;
  }

  @action setUser(user) {
    this.user = user;
  }

  @action setAddress(address) {
    // console.log('SELECT')
    this.currentAddress = address;
    this.tempAddress = null;
    this.closeAddress();
  }

  @action setTempAddress(address) {
    this.tempAddress = address;
    this.currentAddress = {};
  }

  @action openAddress() {
    // console.log('OPEN');
    this.isAddressOpen = true;
  }

  @action closeAddress() {
    // console.log('CLOSE');
    this.isAddressOpen = false;
  }

  @action openAddressForm() {
    // console.log('OPEN');
    this.isAddressFormOpen = true;
  }

  @action closeAddressForm() {
    // console.log('CLOSE');
    this.isAddressFormOpen = false;
  }

  @action clearError(id) {
    const errors = toJS(this.errors).filter((er) => er != id);
    this.errors = errors;
    // this.okToAddCart = 1;
    this.addingToCart = false;
  }

  @action clearItem() {
    this.currentItem = {};
    this.currentAttributes = [];
    this.quantity = 1;
    this.addingToCart = false;
    // this.okToAddCart = 1;
  }

  @action selectItem(item) {
    // console.log("SEL::", item);
    this.currentItem = item;
  }

  @action setNote(note) {
    // console.log("SEL::", item);
    this.note = note;
  }

  @action clearNote() {
    // console.log("SEL::", item);
    this.note = '';
  }

  @action plusQuantity() {
    // console.log("SEL::", item);
    this.quantity = this.quantity + 1;
  }

  @action minusQuantity() {
    // console.log("SEL::", item);
    if (this.quantity > 1) this.quantity = this.quantity - 1;
  }

  @action plusCartQuantity(id) {
    // console.log('SEL::', id);
    const cart = this.cart.map((item) => {
      let quantity = item.quantity;
      if (item.id === id) {
        quantity += 1;
        // console.log(
        //   'YES::',
        //   id,
        //   item.id,
        //   quantity,
        //   Number(item.total),
        //   Number(quantity),
        //   Number(item.total) * Number(quantity)
        // );
      }
      return {
        ...item,
        quantity: quantity,
        display_unit_price: Number(item.unit_price) * Number(quantity),
        display_total: Number(item.total) * Number(quantity)
      };
    });
    this.cart = cart;
  }

  @action minusCartQuantity(id) {
    // console.log('SEL::', id);
    const cart = this.cart.map((item) => {
      let quantity = item.quantity;
      if (item.id === id) {
        // console.log('YES::', id, item.id);
        quantity -= 1;
      }
      // console.log('QUTY:', quantity)
      return {
        ...item,
        quantity: quantity,
        display_unit_price: Number(item.unit_price) * Number(quantity),
        display_total: Number(item.total) * Number(quantity)
      };
    });

    this.cart = cart.filter(
      (item) => item.quantity > 0 && item.display_total > 0
    );
  }

  @action addCustomNote(id, note) {
    // console.log('SEL::', id);
    const cart = this.cart.map((item) => {
      if (item.id === id) {
        item.note = note;
      }
      return item;
    });

    this.cart = cart;
  }

  @action clearCartItem(id) {
    // console.log('SEL::', id);
    const cart = this.cart.filter((item) => item.id !== id);

    this.cart = cart;
  }

  @action resetCart() {
    // console.log("SEL::", item);
    this.cart = [];
  }

  @action orderComplete() {
    this.resetCart();
    this.clearNote();
    this.clearDiscount();
    this.setLoyaltyValue(0);
    this.isSubmitting = false;
    this.deliverySchedule = null;
    // this.resetCart();
  }

  @action resetOutlet() {
    // console.log("SEL::", item);
    this.currentOutlet = {};
  }

  @action resetAll() {
    // console.log("SEL::", item);
    this.orderComplete();
    this.resetOutlet();
  }

  // @action selectAttributes(group_name, attributes) {
  //   const selected = toJS(this.currentAttributes);
  //   selected[group_name] = attributes;
  //   this.currentAttributes = selected;
  //   console.log("ATTRS:", group_name, toJS(this.currentAttributes));
  // }

  @action selectAttributes(group_id, attributes) {
    const selected = toJS(this.currentAttributes).filter(
      (el) => el.group_id !== group_id
    );
    selected.push(...attributes);
    // selected[group_id] = attributes;
    this.currentAttributes = selected;
    // console.log("ATTRS:", group_id, toJS(this.currentAttributes));
  }

  // @action addAttribute(attribute) {
  //   this.currentAttributes.push(attribute);
  //   // console.log("ATTRS:", toJS(this.currentAttributes));
  // }

  @action removeAttribute(attribute) {
    const filtered = this.currentAttributes.filter(
      (el) => el.id !== attribute.id
    );
    this.currentAttributes = filtered;
    // console.log("ATTRS:", toJS(this.currentAttributes));
  }

  @action clearAttributes() {
    this.currentAttributes = [];
    // console.log("ATTRS:", toJS(this.currentAttributes));
  }

  @action addToCart() {
    this.addingToCart = true;
    if (!this.errors.length) {
      // console.log('NO ERRORS:', toJS(this.currentItem));
      const cartItem = {};
      cartItem.id = uuidv4();
      cartItem.menu_id = this.currentItem.id;
      cartItem.cat_id = this.currentItem.categoryId;
      cartItem.name = this.currentItem.name;
      cartItem.quantity = this.quantity;
      cartItem.unit_price =
        Number(this.currentItem.sales_price) > 0
          ? this.currentItem.sales_price
          : this.currentItem.price;

      cartItem.is_sale_item = Number(this.currentItem.sales_price) > 0;
      cartItem.total = this.currentTotalAmount;

      cartItem.display_unit_price =
        (Number(this.currentItem.sales_price) > 0
          ? this.currentItem.sales_price
          : this.currentItem.price) * this.quantity;
      cartItem.display_total = this.currentTotalAmount * this.quantity;
      cartItem.display_total_original = cartItem.display_total;

      if (cartItem.is_sale_item)
        cartItem.display_total_original =
          cartItem.display_total +
          (Number(this.currentItem.price) -
            Number(this.currentItem.sales_price)) *
            cartItem.quantity;

      cartItem.modifiers = toJS(this.currentAttributes);
      // console.log('CARTITEM:', toJS(cartItem));
      // console.log('CART:', toJS(this.cart));
      let found = false;
      let newCart = toJS(this.cart).map((el) => {
        // console.log('COMPARE::', JSON.stringify({...el, id: 0, display_unit_price: 0, display_total: 0}), JSON.stringify({...cartItem, id: 0, display_unit_price: 0, display_total: 0}));
        if (
          _.isEqual(
            { ...el, id: 0, display_unit_price: 0, display_total: 0 },
            { ...cartItem, id: 0, display_unit_price: 0, display_total: 0 }
          )
        ) {
          let quantity = el.quantity + 1;
          el.quantity = quantity;
          el.display_unit_price = Number(el.unit_price) * Number(quantity);
          el.display_total = Number(el.total) * Number(quantity);
          found = true;
          // console.log('FOUND')
        }
        return el;
      });
      if (cartItem.display_total > 0 && cartItem.name != undefined) {
        if (found) {
          // console.log('FOUND ::::')
          this.cart = newCart;
        } else {
          // console.log('NOT FOUND ::::')
          this.cart.push(cartItem);
        }
      }

      this.clearItem();

      this.cleanCart();

      return true;
    } else {
      // console.log('NOT OKK');
      return false;
    }
  }

  @action cleanCart() {
    let cart = [...this.cart].filter((el) => el.display_total > 0);

    this.cart = cart;
  }

  @action submitOrder() {
    // console.log('SUBMITTING ORDER...');
    this.isSubmitting = true;
    const cartData = {
      customer_id: this.user.id,
      channel: this.channel || 'web',
      note: this.note,
      ordering_service: this.orderType,
      payment_method_id: this.paymentMethod.id,
      // area_id: cartStore.currentArea.id,
      redeem_loyalty_amount: this.loyaltyValue,
      useRewardPoints: false,
      voucher_code: null,
      items: toJS(this.cart)
    };

    if (this.orderType == 'delivery') {
      cartData.address_id = this.currentAddress.id;
    }

    if (this.stripe_payment_ref && this.paymentMethod.id == 1) {
      cartData.stripe_payment_ref = this.stripe_payment_ref;
    }

    if (this.orderType == 'pickup' && !this.user.name) {
      if (this.userForm && this.userForm.name) {
        cartData.name = this.userForm.name;
      } else {
      }
    }

    if (this.deliverySchedule) {
      cartData.scheduled_at = moment(this.deliverySchedule).format(
        'YYYY-MM-DD HH:mm'
      );
    }

    if (this.discount && this.discount.code) {
      cartData.discount_code = this.discount.code;
    }

    // setCart(cartData);

    // console.log(
    //   'CART DATA:',
    //   toJS(cartData),
    //   cartStore.currentRestaurant.id,
    //   cartStore.currentOutlet.id
    // );

    agent.Data.post(`/order/submit`, cartData)
      .then((res) => {
        // console.log('ORDER:', res);
        const data = res; //

        if (data.order_no) {
          authStore.setLastOrder(data);
          if (data.access_key) {
            // console.log('Order Complete');
            this.orderComplete();
            if (this.currentRestaurant.domain)
              Router.replace(`/checkout/complete`);
            else
              Router.replace(
                `/[restaurant]/checkout/complete`,
                `/${this.currentRestaurant.url}/checkout/complete`
              );
          } else if (data.payment_gateway && data.payment_gateway.provider) {
            // console.log('Redirecting to payment page');
            if (this.currentRestaurant.domain)
              Router.replace(`/checkout/payment`);
            else
              Router.replace(
                `/[restaurant]/checkout/payment`,
                `/${this.currentRestaurant.url}/checkout/payment`
              );
          }
        }
        // cartStore.setMobile(mobile);
      })
      .catch((err) => {
        // console.log('ERROR:', err.response, err.response.body);
        this.isSubmitting = false;
        if (err.response && err.response.status === 401) {
          // console.log('USER NOT VALID');
          authStore.resetUser();
          if (this.currentRestaurant.domain) Router.replace(`/auth`);
          else
            Router.replace(
              `/[restaurant]/auth`,
              `/${this.currentRestaurant.url}/auth`
            );
        } else {
          showErrorMessage(err, 'Order Error');
          // toast.notify(`Something went wrong.`, {
          //   title: 'Order error.',
          //   type: 'error'
          // });
        }
      });
  }

  @action checkOrderStatus() {
    // console.log('SUBMITTING ORDER...');
    const data = {
      order_id: authStore.lastOrder.order_id,
      order_no: authStore.lastOrder.order_no,
      payment_ref: authStore.lastOrder.payment_ref
    };

    // setCart(cartData);

    // console.log('CART DATA:', toJS(data));

    agent.Data.post(`/order/payment/pre-auth-check`, data)
      .then((res) => {
        // console.log('ORDER:', res);
        const data = res; //

        if (data.message && data.code) {
          if (data.access_key) {
            authStore.setLastOrder(data);
            this.orderComplete();
          } else {
            // console.log('No access key');
            // toast.notify(
            //   `You are changing your location. Your cart will be cleared.`,
            //   { title: 'Area changed!' }
            // );
          }

          switch (data.code) {
            case 2:
              if (this.currentRestaurant.domain)
                Router.replace(`/checkout/complete`);
              else
                Router.replace(
                  `/[restaurant]/checkout/complete`,
                  `/${this.currentRestaurant.url}/checkout/complete`,
                  {
                    shallow: true
                  }
                );
              break;

            case 3:
              if (this.currentRestaurant.domain)
                Router.replace(`/checkout/complete`);
              else
                Router.replace(
                  `/[restaurant]/checkout/complete`,
                  `/${this.currentRestaurant.url}/checkout/complete`,
                  {
                    shallow: true
                  }
                );
              break;

            default:
              toast.notify(`Something went wrong with payment.`, {
                title: 'Payment error.',
                type: 'error'
              });
              break;
          }
        }
        // cartStore.setMobile(mobile);
      })
      .catch((err) => {
        // console.log('ERROR:', err, err.response);
        if (err.response && err.response.status === 401) {
          // console.log('USER NOT VALID');
          authStore.resetUser();
          if (this.currentRestaurant.domain) Router.replace(`/auth`);
          else
            Router.replace(
              `/[restaurant]/auth`,
              `/${this.currentRestaurant.url}/auth`
            );
        }
      });
  }

  @computed get isAsap() {
    return (
      this.currentOutlet &&
      this.currentOutlet.scheduling_hours &&
      this.currentOutlet.scheduling_hours[this.orderType.toLowerCase()] &&
      this.currentOutlet.scheduling_hours[this.orderType.toLowerCase()].Today &&
      this.currentOutlet.scheduling_hours[this.orderType.toLowerCase()].Today[0] &&
      this.currentOutlet.scheduling_hours[this.orderType.toLowerCase()].Today[0]
        .start &&
        isBefore(addMinutes(new Date(`${this.currentOutlet.scheduling_hours[this.orderType.toLowerCase()].Today[0].date}T${this.currentOutlet.scheduling_hours[this.orderType.toLowerCase()].Today[0].start}`), -15), new Date())
    );
  }

  @computed get currentTotalAmount() {
    // console.log("CUR:", this.currentItem);
    // if (!this.currentItem.price) return 0;
    const price = Number(
      Number(this.currentItem.sales_price) > 0
        ? this.currentItem.sales_price
        : this.currentItem.price
    );
    let attribute_price = { price: 0 };
    if (this.currentAttributes.length) {
      attribute_price = toJS(this.currentAttributes).reduce((prev, cur) => {
        return {
          price: Number(prev.price) + Number(cur.price)
        };
      });
    }
    // console.log("PRICE:", price, attribute_price);
    return price + Number(attribute_price.price);
  }

  @computed get discountValue() {
    if (this.discount) {
      return this.discount.discount_type.id == 1
        ? `${Number(this.discount.discount_value)}%`
        : `${this.currentRestaurant.currency_symbol} ${this.discount.discount_value}`;
    }

    return 0;
  }
  @computed get cartTotalAmount() {
    let total = { total: 0, display_total: 0 };
    let saleItemsTotal = { total: 0, display_total: 0 };

    let delivery_fee = 0;
    if (this.orderType == 'delivery') {
      delivery_fee = this.deliveryInfo.delivery_fee
        ? this.deliveryInfo.delivery_fee
        : this.currentOutlet.delivery_fee;
    }

    const cart = [...toJS(this.cart)];
    // Object.assign(cart, toJS(this.cart));
    // console.log('CART TOTAL::', cart);

    if (cart.length) {
      total = cart.reduce((prev, cur) => {
        // console.log('0:', prev, cur)
        return {
          display_total: Number(prev.display_total) + Number(cur.display_total),
          total: Number(prev.total) + Number(cur.total)
        };
      });
      // console.log('1:', total)
      const saleItems = cart.filter((el) => el.is_sale_item == true);
      if (saleItems.length) {
        saleItemsTotal = saleItems.reduce((prev, cur) => {
          return {
            display_total:
              Number(prev.display_total) + Number(cur.display_total),
            total: Number(prev.total) + Number(cur.total)
          };
        });
      }

      // console.log('SALE TOTAL:', total);
    } else {
      total.grand_total = 0;
      total.vat = 0;
      total.delivery_fee = delivery_fee;
      // console.log('NOO:', total);
      return total;
    }

    if (total.total == null || total.display_total == null) {
      // this.resetCart();

      total.total = 0;
      total.display_total = 0;
      total.grand_total = 0;
      total.vat = 0;
      total.delivery_fee = delivery_fee;
    }
    // return total;

    total.vat = 0;
    total.delivery_fee = delivery_fee;

    total.discount = 0;
    total.discounted_total = total.display_total;
    total.discountable_total = total.display_total;

    if (this.discount && this.discount.exclude_promotional_item) {
      total.discountable_total =
        total.display_total - saleItemsTotal.display_total;
    }

    if (
      this.discount &&
      Number(this.discount.discount_value) > 0 &&
      Number(this.discount.minimum_order) <= total.display_total
    ) {
      total.discount =
        this.discount.discount_type.id == 1
          ? (total.discountable_total * Number(this.discount.discount_value)) /
            100
          : Number(this.discount.discount_value);
      if (
        Number(this.discount.maximum_discount) > 0 &&
        total.discount > Number(this.discount.maximum_discount)
      ) {
        total.discount = Number(this.discount.maximum_discount);
      }

      if (total.discount > total.discountable_total)
        total.discount = Number(total.discountable_total);

      total.discounted_total = Number(total.display_total) - total.discount;
    }

    if (
      Number(this.currentOutlet.tax_rate) > 0 &&
      this.currentOutlet.tax_type == 'exclusive'
      // Number(this.currentOutlet.inclusive_tax)
    ) {
      // total.vat =
      //   ((Number(total.discounted_total) + Number(delivery_fee)) *
      //     Number(this.currentOutlet.tax_rate)) /
      //   100;
      total.vat =
        (Number(total.discounted_total) * Number(this.currentOutlet.tax_rate)) /
        100;
    }

    total.grand_total =
      Number(total.display_total) -
      total.discount +
      Number(delivery_fee) +
      Number(total.vat) -
      Number(this.loyaltyValue);
    // console.log('TOTAL:', total);
    return total;
  }

  // @computed get cartData() {
  //   const order = {
  //     customer_id: 1,
  //     address: "Test Address",
  //     channel: "web",
  //     note: null,
  //     ordering_service: "delivery",
  //     payment_method_id: "1",
  //     area_id: this.currentArea.id,
  //     useRewardPoints: false,
  //     voucher_code: null,
  //     item: this.cart
  //   };
  // }
}

const hydrate = create({
  // storage: localForage,   // or AsyncStorage in react-native.
  //                         // default: localStorage
  // jsonify: false  // if you use AsyncStorage, here shoud be true
  // default: true
});

// create the state
export const cartStore = new CartStore();
// const router = useRouter();
let storeName = 'cart';
if (process.browser) {
  let pathname = window.location.pathname.trim().split('/');
  // console.log('PATH NAME', pathname);
  console.log('DOMAIN::', cartStore.currentRestaurant.domain);
  if (pathname[1] !== undefined) {
    if (['en', 'ar', 'delivery', 'pickup', 'menu', 'cart', 'checkout', 'auth'].includes(pathname[1])) {
      console.log('HAS DOMAIN::');
      storeName = `cart`;
    }
    else {
      if (!['en', 'ar'].includes(pathname[1])) storeName = `cart:${pathname[1]}`;
      else storeName = `cart:${pathname[2]}`;
    }
    // storeName = `cart:test`;
  }
  // console.log(pathname.split('/')[1]);
  hydrate(storeName, cartStore).then(() => console.log(''));
  // console.log(pathname.split('/')[1]);
  // hydrate('auth', cartStore.user).then(() =>
  //   console.log("cartStore user has been hydrated")
  // );
}

export default CartStore;
